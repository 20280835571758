import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  buttonLabel?: string
  buttonURL?: string
  image?: ImageProps
  title?: string
}

export const Service = memo(function Service({
  buttonLabel,
  buttonURL,
  image,
  title,
}: Props) {
  return (
    <Container to={buttonURL || '#'}>
      {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      {title ? (
        <Title className="service-title" dial={4} row>
          <TitleLabel>{title}</TitleLabel>
        </Title>
      ) : null}
      {buttonLabel ? (
        <Button className="service-button">{buttonLabel}</Button>
      ) : null}
    </Container>
  )
})

const Container = styled(Link)`
  width: 33.333%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 45%;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 2;
  }
  &:hover {
    &:before {
      opacity: 0.6;
    }
    img {
      transform: scale(1.1);
    }
    .service-wrap {
      transform: none;
    }
    .service-title {
      transform: translateY(-2.625rem);
    }
    .service-button {
      opacity: 1;
      &:after {
        right: 0;
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1);
    transition: all 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5rem 1.875rem 4.375rem;
    text-align: center;
  }
`

const Title = styled(FlexBox)`
  min-height: 3.375rem;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  letter-spacing: 0.25rem;
  line-height: 1.6875rem;
  position: absolute;
  bottom: 3.333vw;
  right: 3.333vw;
  left: 3.333vw;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    flex: 1;
    margin-left: 2.778vw;
  }

  @media (max-width: 1023px) {
    min-height: 0;
    position: relative;
    bottom: auto;
    right: auto;
    left: auto;
    transform: none !important;
    &:after {
      display: none;
    }
  }
`

const TitleLabel = styled.div`
  max-width: 13.125rem;

  @media (max-width: 1023px) {
    max-width: none;
    margin: auto;
  }
`

const Button = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  opacity: 0;
  margin-top: 0.875rem;
  padding: 0 0.5rem 0.5rem;
  position: absolute;
  bottom: 3.333vw;
  left: 3.333vw;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  z-index: 2;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    bottom: 0;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    opacity: 1 !important;
    position: relative;
    bottom: auto;
    left: auto;
    &:after {
      right: 0;
    }
  }
`
