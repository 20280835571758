import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Section } from 'app/components/Common/Section'
import { Instagram } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
  languageCode: string
}

export const SocialWall = memo(function SocialWall({
  images,
  languageCode,
}: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      <Section label="follow-us" languageCode={languageCode} />
      <Hashtag>
        <Instagram />
        #valkaranarelais
      </Hashtag>
      <Images dial={2} row wrap>
        {images.slice(0, 6).map((item, index) => (
          <LazyLoadImage key={index} effect="blur" {...item} />
        ))}
      </Images>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 19.125rem;
  padding: 2.5rem 0 3.75rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 12.5rem;
    padding: 2.5rem 0 1.875rem;
  }
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  text-transform: uppercase;

  svg {
    display: block;
    width: auto;
    height: 1.25rem;
    margin: 0 auto 0.375rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark1};
  }
`

const Images = styled(FlexBox)`
  margin: 2.5rem -1.5625rem 0;

  .lazy-load-image-background {
    width: calc(16.666% - 3.125rem) !important;
    height: auto !important;
    margin: 0 1.5625rem;
    padding-bottom: calc(16.666% - 3.125rem);
    position: relative;
    top: auto;
    left: auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin: 2.25rem -0.9375rem 0;

    .lazy-load-image-background {
      width: calc(16.666% - 1.875rem) !important;
      margin: 0 0.7813rem;
      padding-bottom: calc(16.666% - 1.875rem);
    }
  }

  @media (max-width: 767px) {
    margin: 2.25rem -0.375rem 0;

    .lazy-load-image-background {
      width: calc(16.666% - 0.75rem) !important;
      margin: 0 0.375rem;
      padding-bottom: calc(16.666% - 0.75rem);
    }
  }
`
