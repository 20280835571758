import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  images: ImageProps[]
  title?: string
}

export const Awards = memo(function Awards({ images, title }: Props) {
  if (images.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Images dial={2} row wrap>
        {images.map((item, index) => (
          <LazyLoadImage effect="blur" key={index} {...item} />
        ))}
      </Images>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark4};
  padding: 3.125rem 3.333vw 3.75rem;
  text-align: center;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
`

const Images = styled(FlexBox)`
  .lazy-load-image-background {
    width: auto !important;
    max-width: 7.5rem;
    height: auto !important;
    margin: 1.25rem 0.625rem 0;
    position: relative;
    top: auto;
    left: auto;
  }
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 767px) {
    .lazy-load-image-background {
      max-width: 5.625rem;
      margin: 0.75rem 0.375rem 0;
    }
  }
`
