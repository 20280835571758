import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const Visual = memo(function Visual({
  cta,
  image,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      <Wrap>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <CTA {...cta} />
          </FadeInUp>
        ) : null}
      </Wrap>
    </Container>
  )
})

const Container = styled.section`
  padding: 10.5rem 13.889vw;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding: 7.5rem 1.875rem;
    text-align: center;
  }
`

const Wrap = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: relative;
  z-index: 2;
`

const Title = styled.h2`
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 4.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1023px) {
    font-size: 40px;
    line-height: 3.375rem;
    margin-bottom: 0;
  }
`

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  margin-top: 1.375rem;
  padding-left: 3.75rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    top: 0.75rem;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: inline-block;
    margin: 1.125rem auto 0;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;

  @media (max-width: 1023px) {
    margin-top: 3rem;
  }
`
