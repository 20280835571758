import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  Props as ServiceProps,
  Service,
} from 'app/components/ServicesPreview/Service'
import React, { memo } from 'react'

export interface Props {
  description?: string
  services: ServiceProps[]
  title?: string
}

export const ActivityPreview = memo(function ActivityPreview({
  description,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.slice(0, 3).map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 12.5rem;
  padding: 0 6.667vw 7.5rem;
  position: relative;
  text-align: center;
  &:after {
    content: '';
    width: 100%;
    height: 65%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
    padding: 0 1.875rem 5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 4.25rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
  }
`

const Services = styled(FlexBox)`
  margin: 8.75rem -1.5rem 0 0;
  a {
    width: calc(33.333% - 1.5rem);
    margin-right: 1.5rem;
    text-align: center;
    &:nth-of-type(2) {
      transform: translateY(-3.75rem);
    }
    .service-title {
      min-height: auto;
      justify-content: center;
      &:after {
        display: none;
      }
    }
    .service-button {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 1023px) {
    margin: 3rem 0 0 0;
    a {
      width: 100%;
      margin-right: 0;
      padding: 5rem 1.875rem 4.375rem;
      text-align: center;
      &:nth-of-type(2) {
        transform: none;
      }
      .service-button {
        left: auto;
        transform: none;
      }
    }
  }
`

const Description = styled.div`
  max-width: 38.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 4.75rem auto 0;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 3rem;
  }
`
