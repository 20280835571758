import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  title?: string
}

export const Banner = memo(function Banner({
  cta,
  description,
  image,
  title,
}: Props) {
  if (!title || !description) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}

      {cta ? (
        <FadeInUp>
          <CTA {...cta} />
        </FadeInUp>
      ) : null}

      {image ? (
        <Logos>
          <LazyLoadImage effect="blur" {...image} />
        </Logos>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 5.625rem 1.875rem;
  text-align: center;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 4.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
    margin-bottom: 0;
  }
`

const Description = styled.div`
  max-width: 45rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  margin: auto;

  @media (max-width: 1023px) {
    max-width: none;
    font-size: 1rem;
    line-height: 1.75rem;
    padding-right: 0;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`

const Logos = styled.div`
  margin-top: 3.75rem;

  .lazy-load-image-background {
    width: auto !important;
    height: auto !important;
    position: relative;
    top: auto;
    left: auto;
  }

  @media (max-width: 1199px) {
    img {
      width: 100%;
      height: auto;
    }
  }
`
